import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import { Container } from "../../components/System/Grids";
import PageIntro from "../../components/PageIntro/PageIntro";
import { Tag } from "../../components/UI/index";
// import { DateTime } from "luxon";

import moment from "moment";
import TeacherBar from "../../components/TeacherBar";

import SectionFaq from "../../components/SectionFaq";
import groupLessonsData from "../../../data/groupLessonsData";
import SocialShare from "../../components/SocialShare";
import Testimonials from "../../components/testimonails";
import GroupCard from "./components/GroupCard";
import dataStudio from "../../utils/dataStudio";
import { pushDetailView } from "../../utils/data-layer-ecom";

const LiveCourseTemplate = (props) => {
  const {
    node_locale,
    contentful_id,
    __typename,
    slug,
    title,
    description,
    seoDescription,
    seoTitle,
    studentLevel,
    // subtitle,
    price,
    teachers,
    groups,
    cover,
    previewAsset,

    intro,
  } = props.data.liveCourses;

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
    ],
  };
  environment.theme = "pink";

  const seo = {};

  seo.description = seoDescription ? seoDescription : "";
  seo.title = seoTitle ? seoTitle : title;

  environment.seo = seo;

  const contentFaq = groupLessonsData[environment.lang].faq;
  const contactUrl = `mailto:info@thedutchonlineacademy.com?body=${encodeURI(
    `Hello! I was seeing the course:${title}, and I wanted to...`,
  )}&subject=${encodeURI(`Joining / question about course ${title}`)}`;

  useEffect(() => {
    pushDetailView({
      id: contentful_id,
      name: title,
      category: "live-course",
      price: price,
      location: "PDP",
    });
  }, []);

  return (
    <Layout environment={environment}>
      <PageIntro>
        {studentLevel && studentLevel.length > 0 && (
          <Tag.Container style={{ border: "none", margin: "0px" }}>
            <div>
              {studentLevel.map((studentLvl, index) => (
                <Tag.Default key={index} className={"tag"}>
                  {studentLvl.level}
                </Tag.Default>
              ))}
            </div>
          </Tag.Container>
        )}
        <h1 style={{ margin: "14px 0px 12px" }}>{title}</h1>
        <div
          style={{ margin: "18px 0" }}
          dangerouslySetInnerHTML={{
            __html: intro.childMarkdownRemark.html,
          }}
        />
        {/* <span>Total course price: {price} €</span> */}
        {previewAsset &&
          previewAsset.length > 0 &&
          previewAsset[0]?.file?.url ? (
          <>
            <a
              className="sneakpeek"
              target="_black"
              href={previewAsset[0].file.url}
            >
              View sneakpeek
            </a>
            <span style={{ color: "white", margin: "0 8px" }}>{" | "}</span>
          </>
        ) : null}

        <a
          href={contactUrl}
          style={{ color: "white", margin: "0 8px" }}
          onClick={() =>
            dataStudio({
              action: "contact_group_courses",
              course: title,
            })
          }
        >
          <u>ask any question</u>
        </a>
        <span style={{ color: "white", margin: "0 8px" }}>{" | "}</span>

        <a href="#testimonials" style={{ color: "white", margin: "0 8px" }}>
          <u>View reviews</u>
        </a>
        <br />
        <br />
        <TeacherBar teachers={teachers} />

        <SocialShare cta={"Share the course"} environment={environment} />
        <Testimonials
          productUrl={environment.path}
          limit={5}
          type={"carousel"}
        />
      </PageIntro>

      <Container>
        <ContentfulRenderer json={description} lang={environment.lang} />

        <h2>Open groups:</h2>
        {groups &&
          groups.length > 0 &&
          groups
            .filter((group) => {
              if (group.soldOut) return false;

              const date = moment(group.starting);
              // var dateMoment = moment(group.starting)
              // var date = DateTime.fromISO(group.starting)
              // console.log("dates comparison Moment->",dateMoment.toString()," other->",date.toString())

              // var nowMoment = moment().add(-1, "days")
              // var now = DateTime.now().minus({days:1})
              // console.log("now comparison Moment->",nowMoment.toString()," other->",now.toString())
              const now = moment().add(-1, "days");

              return !(now > date);
            })
            .map((group) => (
              <GroupCard
                group={group}
                forSale={true}
                course={title}
                key={group.id}
                slug={slug}
                cover={cover}
                contentful_id={group.contentful_id}
                price={price}
                environment={environment}
              />
            ))}
        <p style={{ textAlign: "right" }}>
          *All hours are in Amsterdam Time (CET)
        </p>

        {/* <h2>Past recent groups:</h2>
        {groups &&
          groups.length > 0 &&
          groups
            .filter((group) => {
              if (group.soldOut) return true;
              const date = moment(group.starting);
              const now = moment().add(-1, "days");
              return now > date;
            })
            .reverse()
            .filter((group, i) => (i > 1 ? false : true))

            .map((group) => (
              <GroupCard
                group={group}
                forSale={false}
                course={title}
                key={group.id}
                slug={slug}
                cover={cover}
                price={price}
                environment={environment}
                contentful_id={contentful_id}
              />
            ))} */}
      </Container>

      <SectionFaq content={contentFaq} />
    </Layout>
  );
};

export const groupCoursesQuery = graphql`
  query groupCoursesQuery($lang: String!, $id: String!) {
    liveCourses: contentfulLiveOnlineCourses(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      slug
      __typename
      price
      contentful_id
      seoTitle
      seoDescription
      node_locale
      description {
        raw
      }

      intro {
        childMarkdownRemark {
          html
        }
      }
      studentLevel {
        level
        levelShorthand
      }
      groups {
        id
        starting(locale: "nl")
        ending(locale: "nl")
        title
        soldOut
        dayOfTheWeek
        contentful_id
      }
      previewAsset {
        file {
          contentType
          fileName
          url
          details {
            size
          }
        }
      }
      cover {
        fluid: gatsbyImageData(width: 1200, layout: CONSTRAINED)
        fixed: gatsbyImageData(width: 150)
      }
      teachers {
        title
        picture {
          gatsbyImageData(width: 300)
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default LiveCourseTemplate;
