import React from "react";
import Styled from "./styled";
import Whatsapp from "../../assets/icons/share/whatsapp.svg";
import Facebook from "../../assets/icons/share/facebook.svg";
import Email from "../../assets/icons/share/email.svg";
import dataStudio from "../../utils/dataStudio";

const SocialShare = (props) => {
  const { cta = "Share:", environment } = props;
  return (
    <Styled.Wrapper>
      <h4>{cta}</h4>

      <Styled.Icons>
        <a
          href={
            "whatsapp://send?text=Hey! I found this! https://thedutchonlineacademy.com" +
            environment.path +
            "?utm_source=footer_share&utm_medium=referral&utm_campaign=share%20on%20whatsapp%20button"
          }
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            dataStudio({
              action: "share",
              platform: "whatsapp",
              path: environment.path,
            })
          }
        >
          <Whatsapp />
        </a>
        <a
          href={
            "https://www.facebook.com/sharer/sharer.php?u=https://thedutchonlineacademy.com" +
            environment.path +
            "?utm_source=footer_share&utm_medium=referral&utm_campaign=share%20on%20Facebook%20button"
          }
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            dataStudio({
              action: "share",
              platform: "facebook",
              path: environment.path,
            })
          }
        >
          <Facebook />
        </a>
        <a
          href={`mailto:?body=${encodeURI(
            `Hey! I found this group online Dutch course https://thedutchonlineacademy.com/${environment.path} `,
          )}&subject=${encodeURI(`Dutch group course`)}`}
          title="Share by Email"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            dataStudio({
              action: "share",
              platform: "email",
              path: environment.path,
            })
          }
          label="email-icon"
        >
          <Email label="email-icon" />
        </a>
      </Styled.Icons>
    </Styled.Wrapper>
  );
};
export default SocialShare;
