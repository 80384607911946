import styled, { css } from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

const GroupWrapper = styled.div`
  font-size: 0.8rem;
  background: linear-gradient(
    135deg,
    white,
    ${(props) => props.theme.default_lighterTwo}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
    -2px -2px 4px ${(props) => props.theme.default_lighterTwo};
  border-radius: 10px;
  padding: 32px 16px;
  min-width: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  ${MEDIA.PHONE`
		flex-direction:column;
		align-items: flex-start;

	`}
`;

const Body = styled.div`
  .block,
  button,
  a {
    display: block;
    margin-bottom: 12px;
  }
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `}
`;

const allStyled = {
  GroupWrapper,
  Body,
};

export default allStyled;
