const groupLessons = {
  en: {
    hero: {
      title: `Online Dutch Group Courses`,
      subtitle: `Learn Dutch with dynamic group lessons. We offer Dutch conversation courses and Dutch grammar courses. Groups are small and guided by a professional Dutch teacher. Material is always included.`,
    },
    seo: {
      title: `Online Dutch Group Courses, by The Dutch Online Academy`,
      description: `Learn Dutch with dynamic group lessons. We offer Dutch conversation courses and Dutch grammar courses. Groups are small and guided by a professional Dutch teacher. Material is always included.`,
    },
    ui: {
      goodFor: `Good for`,
      backToPodcast: `See all chapters`,
      podcastSlug: `/podcast`,
    },
    faq: {
      title: `Frequently asked questions`,
      items: [
        {
          q: `How do the lessons work?`,
          a: `Each day we have a lesson (you will see it depending on the group you join) you will receive a Zoom link 30 mins before the lesson. When you click the link you will join a zoom call with your teacher and colleagues to have your lesson!`,
        },
        {
          q: `Can I pay by transfer?`,
          a: `Yes. Just select the group you want and click the write an email to join button and we will give you more details!`,
        },
        {
          q: `Is it possible to receive an invoice?`,
          a: `Yes. We are a registered Dutch business and provide invoices.`,
        },

        {
          q: `Can you tell me a bit more about yourself?`,
          a: `I am a professional Dutch teacher, living in Amsterdam. I used to teach Dutch at a school in the Netherlands, but I moved to Spain in 2017 (and back to Amsterdam in 2020), which made me explore the possibilities to teach Dutch offline and online. I have given over 2500 online Dutch lessons. I am still learning Spanish myself and I know the process is long and difficult at times, but I enjoy the little moments of success. The feeling that conversations get more and more natural is really rewarding. In my free time I enjoy running, reading, spending time with family and friends and cooking (+ eating ;).
					`,
        },

        {
          q: `I don't know what I need to work on. Is that a problem?`,
          a: `Absolutely not. In private lessons I will quickly get an idea of your needs and will share my opinion with you, giving you clear tips to get your Dutch to the next level. In conversation lessons, you will get lots of opportunities to practice your speaking skills. In the structured group lessons I will make sure every student gets the support he or she needs and I will give you personal feedback outside the lessons too. 
					`,
        },
        {
          q: `I want to combine our lessons with self study, is that possible?`,
          a: `Of course. Especially private lessons and structured group lessons go well with self study. Homework is included in packages and it will be completely tailored to your needs. In the structured group lessons, we will work with a book. Certain exercises are perfect for self-study. I will indicate which ones and give feedback and support, also outside the lessons.`,
        },
        {
          q: `I am not sure about my level. What do I do?
					`,
          a: `If you have followed Dutch courses before, you can start where you left off. So if you, for example, passed an A2 exam, you can move on working from A2 to B1. You can always send an email if you are in doubt. I will then help you decide which course fits you best. For private lessons, your level doesn’t matter. I will always tailor the material to your needs, whether you are a beginner or working towards C1.`,
        },
        {
          q: `Do you have any other doubt?
					`,
          a: `Do not hesitate in contacting us in the bottom link at this page to write us an email with whatever doubt you may have, we will be delighted to respond as fast as we can.`,
        },
      ],
    },
  },
};

export default groupLessons;
