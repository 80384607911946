import React, { useState } from "react";
import Styled from "./styled";
import { Button } from "../UI/index";
import { GatsbyImage } from "gatsby-plugin-image";

const TeacherBar = (props) => {
  const { teachers } = props;
  const [showInfo, set_showInfo] = useState(false);
  return (
    <Styled.Wrapper open={showInfo}>
      <div className="teacher-image">
        <GatsbyImage
          image={teachers.picture.gatsbyImageData}
          alt={teachers.title}
        />
      </div>
      <Styled.Header>
        <div className="top">
          <span className="overline">The instructor</span>
          <h2 style={{ flexGrow: "1" }}>{teachers.title}</h2>
          <Button.PrimaryPrimary onClick={() => set_showInfo(!showInfo)}>
            {showInfo ? "- Less info" : "+ More info"}
          </Button.PrimaryPrimary>
        </div>
        {showInfo && (
          <div
            dangerouslySetInnerHTML={{
              __html: teachers.description.childMarkdownRemark.html,
            }}
          />
        )}
      </Styled.Header>
    </Styled.Wrapper>
  );
};

export default TeacherBar;
