import styled, { css } from "styled-components";
import mixins from "../../theme/mixins";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: stretch; */
  align-items: stretch;
  ${mixins.shadowed.inner.primary};
  border-radius: 12px;
  padding: 14px 12px;
  position: relative;

  h2 {
    margin: 12px 0px 0px 0px;
  }
  .overline {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 0.6rem;
  }
  .teacher-image {
    max-width: 60px;
    /* position: absolute; */
    left: 10px;
    top: 15px;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  margin-left: 10px;
  flex-grow: 1;
  ${(props) =>
    props.open &&
    css`
      :after ;
    `}
  .top {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }
`;

export default {
  Wrapper,
  Header,
};
